import React, { useEffect, useRef, useState } from 'react'
import { readRemoteFile } from 'react-papaparse'
import { useCache } from '../../contexts/CacheContext'
import './tableviewer.scss'
import LoadingBars from '../spinner/horizontalLoader'

const TableViewer = ({ url }) => {
  const { fetchData, getCacheState } = useCache()
  const { isLoading, data, error } = getCacheState(url)

  useEffect(() => {
    fetchData(url, async (finalUrl) => {
      try {
        let { results } = await readTableFromURL({ url: finalUrl })
        return results
      } catch(err) {
        console.error(err.error)
        throw err?.error || 'Error while reading table data from URL'
      }
    })
  }, [url, fetchData])


  return (
    <>
      <div className='sv3-table-title'>
        <h2>
          Table Preview { isLoading && <>&nbsp;&nbsp;<LoadingBars/></> }
        </h2>
      </div>
      {
        data ?
        <div className="sv3-table-container">
          <div className="sv3-table-viewer">
            <table className="sv3-table">
              {
                data?.meta?.fields?.length > 0 ?
                <thead className="sv3-table-head">
                  <tr>{ data?.meta?.fields.map((col, i) => <td key={`head-${i}`}>{col}</td>) }</tr>
                </thead>
                :
                null
              }
              <tbody className="sv3-table-body">
                {
                  data?.data?.length > 0 ?
                  data?.data.map((elem, i) => {
                    let row = Object.values(elem)
                    if(row.length > 0) {
                      return (<tr key={`row-${i}`}>{ row.map((col, j) => <td key={`col-${i}-${j}`}>{col}</td>) }</tr>)
                    } else {
                      return null
                    }
                  })
                  :
                  null
                }
              </tbody>
            </table>
          </div>
        </div>
        :
        null
      }
    </>
  )
}

const readTableFromURL = ({
  url, 
  config={ download:true, header: true, skipEmptyLines: true, preview: 30 }
}) => {
  return new Promise((resolve, reject) => {
    readRemoteFile(
      url, {
        ...config,
        complete: (results, file) => {
          resolve({results, file})
        },
        error: (error, file) => {
          reject({error, file})
        }
      }
    )
  })
}

export default TableViewer