import axios from 'axios'
// var baseURL = `https://sellagen.com/api/`

var baseURL = null
if(process.env.NODE_ENV==='development') {
	baseURL = `http://localhost:4000/api/`
} else {
	baseURL = `https://sellagen.com/api/`
}

const Axios = axios.create({
	baseURL: baseURL,
	withCredentials: process.env.NODE_ENV !=='development'
})

Axios.CancelToken = axios.CancelToken

export default Axios