import React, { createContext, useContext, useReducer, useCallback } from 'react'
import axios from 'axios'
import Axios from '../utilities/axios'

const CacheContext = createContext()

const FETCH_START = 'FETCH_START'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'

const cacheReducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        [action.url]: {
          ...state[action.url],
          isLoading: true,
          error: null
        }
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        [action.url]: {
          data: action.data,
          isLoading: false,
          error: null
        }
      }
    case FETCH_ERROR:
      return {
        ...state,
        [action.url]: {
          ...state[action.url],
          isLoading: false,
          error: action.error
        }
      }
    default:
      return state
  }
}

const getLocalSignedUrl = async (url) => {
  try {
    let result = await Axios.post(`/storage/signed`, { url })
    return result?.data?.signedUrl || null
  } catch (error) {
    console.log('Error getting signed URL:', error)
    return null
  }
}

export const Cache = ({ children }) => {
  const [cache, dispatch] = useReducer(cacheReducer, {})

  const fetchData = useCallback(async (url, customFetch = null) => {
    const cachedData = cache[url]
    
    // If already loading or we have data/error, just return early
    if (cachedData?.isLoading || (cachedData && !cachedData.isLoading)) {
      return
    }

    dispatch({ type: FETCH_START, url })
    
    try {
      let finalUrl = String(url)
      finalUrl = await getLocalSignedUrl(url)
      if (!finalUrl) {
        throw new Error('Cannot get signed URL')
      }

      let data = null
      if (typeof customFetch === 'function') {
        data = await customFetch(finalUrl)
      } else {
        const response = await axios.get(finalUrl)
        data = response.data
      }
      
      dispatch({ type: FETCH_SUCCESS, url, data })
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message
      dispatch({ type: FETCH_ERROR, url, error: errorMessage })
    }
  }, [cache])

  const getCacheState = useCallback((url) => {
    return cache[url] || { isLoading: false, data: null, error: null }
  }, [cache])

  const value = {
    fetchData,
    getCacheState
  }

  return (
    <CacheContext.Provider value={value}>
      {children}
    </CacheContext.Provider>
  )
}

export const useCache = () => {
  const context = useContext(CacheContext)
  if (!context) {
    throw new Error('useCache must be used within a Cache block')
  }
  return context
}