import React from 'react'

const LoadingBars = ({ color = 'currentColor', size = 16, rotate = true, opacity = '70%' }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 24 24`}
      className="inline-block"
      style={{ transform: rotate ? 'rotate(-90deg)' : 'none', transformOrigin: 'center', opacity }}
    >
      <rect x="0" y="0" width="4" height="7" fill={color}>
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="scale"
          values="1,1; 1,4; 1,1"
          begin="0s"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="10" y="0" width="4" height="7" fill={color}>
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="scale"
          values="1,1; 1,4; 1,1"
          begin="0.2s"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="20" y="0" width="4" height="7" fill={color}>
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="scale"
          values="1,1; 1,4; 1,1"
          begin="0.4s"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  )
}

export default LoadingBars