import React from 'react'
import './styles.scss'
import TableViewer from './tableviewer'

const Previewer = ({ label=null, href=null }) => {
  if(!href) return <a href={href}>{label}</a>
  
  const ext = getFileExtension(href)
  if(ext !== '.csv') return null

  return (
    <div className='sv2-prvw-root'>
      <TableViewer url={href}/>
    </div>
  )
}

const getFileExtension = (fileUrl) => {
  try {
    const parsedUrl = new URL(fileUrl)
    const pathname = parsedUrl.pathname
    return pathname.substring(pathname.lastIndexOf('.')) || null
  } catch (error) {
    console.error("Invalid URL:", error.message)
    return null
  }
}

export default Previewer