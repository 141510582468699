import React, { Component, createContext, useRef } from 'react'
import supportedDataTypes from '../pages/SpeedRun/sandbox/dataType'
import parseFunctions from '../pages/SpeedRun/sandbox/parser'
import axios from '../utilities/axios'
import LLMContextLoader from '../pages/SpeedRun/sandbox/context-panel/generalInfoLoader'
import LLMServiceLoader from '../pages/SpeedRun/sandbox/service-panel/generalServiceLoader'
import NotificationManager from '../pages/SpeedRun/sandbox/notification-panel/notificationManager'
import { ChatHistoryTransitionPanel } from '../pages/SpeedRun/sandbox/popups/chat-history'
import { Context } from './GlobalContext'
// import EventManager from '../pages/SpeedRun/sandbox/notification-panel/eventManager'
import Axios from '../utilities/axios'
import { createFileMap } from '../components/file-explorer/storage-components'
import defaultComment from '../assets/misc/ide-deault-comment'

const SandBoxContext = createContext()

const numOfMsgToHandle = 10

class SandBoxHandler extends Component {
  static contextType = Context
  constructor(props) {
    super(props)
    let templateState = {
      functionDetails: {
        title: '',
        description: '',
        // parameter props: title, dataType, description, enum, isRequired
        parameters: [],
        samplePrompts: [],
        output: '',
        outputPrompt: '',
        status: '',
        metadata: {}
      },
      chatID: null,
      prompts: [],
      testPrompts: [],
      isPromptLoading: false,
      axiosController: new AbortController(),
      erroredPrompt: false,
      showSidePanel: null,
      panelMode: '',    // action, integrate
      layout: 'pro',
      functionTestParams: {},
      editorRef: this.props.editorRef,
      // script: "# Write your Python 3 (Version 3.11) function here.\n# Each function has an average execution time of 2 seconds before being interrupted.\n# DO NOT CALL THE ENTRY POINT FUNCTION IN YOUR SCRIPT.\n#\n# This environment includes the following packages: BeautifulSoup, urllib3, requests, pyyaml.\n# We would love to hear from you regarding which packages you'd like to have installed.\n# Join our Discord at https://discord.com/invite/zGdUve2Nhk and let us know.\n",
      script: defaultComment,
      jsonEditorRef: this.props.jsonEditorRef,
      windowStack: ['lvl1-instructions'],    // lvl1-details, lvl1-instructions, lvl1-functesting, lvl1-funclist, lvl1-funcexample
      objectSetup: null,
      overlay: null,
      overlayLeft: null,
      overlayFull: null,
      myFunctions: [],
      contexts: {},
      services: {},
      isOnline: false,
      isContextLoading: false,
      isServiceLoading: false,
      isContextEnabled: localStorage.getItem('context_enabled') === 'true',
      schedules: [],
      env: [],
      authStorageCache: null,
      // isServiceEnabled: localStorage.getItem('service_enabled') === 'true',
      isServiceEnabled: true,
      fileMap: FsMap.fileMap,
      rootFolderId: FsMap.rootFolderId,   // My Drive
      storageEnabled: false,
      isStorageSyncing: false,
      isInitialStorageSync: true,
      chatPanelSecondaryPlaceholderMsg: '',
      metadata: {}    // possible meatadata fields: editorActive, jsonEditorActive, promptTesting
    }
    let prompts = localStorage.getItem('prompts')
    if(prompts) {
      try {
        templateState['prompts'] = JSON.parse(prompts)
      } catch(err) {
        templateState['prompts'] = []
      }
    }
    let context = localStorage.getItem('initial-context')
    if(context) {
      try {
        templateState['context'] = JSON.parse(context)
      } catch(err) {
        templateState['context'] = null
      }
    }
    this.state = {...templateState}
  }

  _isMounted = false
  _intervalID = null

  componentDidMount() {
    this._isMounted = true
  }

  cleanupPrompts = () => {
    this.setState({prompts: []})
    localStorage.setItem('prompts', JSON.stringify([]))
  }

  syncStorage = async () => {
    if(this.state.isStorageSyncing) return
    this.setState({
      isStorageSyncing: true
    })
    try {
      let response = await Axios.get('storage/my-drive')
      let fileList = response.data?.files
      this.setState({
        fileMap: createFileMap(fileList)
      })
    } catch (error) {
      this.setState({
        fileMap: FsMap.fileMap
      })
    } finally {
      this.setState({
        isStorageSyncing: false,
        isInitialStorageSync: false
      })
    }
  }

  isEnvVarAttached = (id) => {
    let env = this.state?.env || []
    let idx = env.findIndex(v => v._id === id)
    if(idx >= 0) {
      return true
    }
    return false
  }

  envVarListUpdate = (envObj, operation) => {
    let env = this.state?.env || []
    if(operation === 'attach') {
      delete envObj['description']
      if(env.findIndex(v => v._id === envObj._id) < 0) {
        env.push(envObj)
      }
    } else if(operation === 'detach') {
      env = env.filter(v => v._id !== envObj._id)
    }
    this.setState({env})
  }

  getObjectDetails = (objectName, objectType) => {
    let {functionDetails} = this.state
    if(objectType === 'function') {
      // console.log('Geting obj details', functionDetails)
      if(objectName === functionDetails?.title) {
        return {
          _title: functionDetails?.title || 'None',
          _description: functionDetails?.description || '',
          _samplePrompts: functionDetails?.samplePrompts || [],
          _outputPrompt: functionDetails?.outputPrompt || '',
          _status: functionDetails?.status || '',
          _enums: [],
          _isRequired: null,
          _dataType: ''
        }
      } else {
        return {
          _title: functionDetails?.title || 'unrecognized',
          _description: functionDetails?.description || '',
          _samplePrompts: functionDetails?.samplePrompts || [],
          _outputPrompt: functionDetails?.outputPrompt || '',
          _status: functionDetails?.status || '',
          _enums: [],
          _isRequired: null,
          _dataType: ''
        }
      }
    } else if(objectName) {
      let {parameters} = this.state?.functionDetails
      let param = parameters.find(obj => obj.title === objectName)
      let isRequiredFlag = null
      if(param?.isRequired === true) isRequiredFlag = true
      else if(param?.isRequired === false) isRequiredFlag = false
      return {
        _title: param?.title || '',
        _dataType: supportedDataTypes.includes(param?.dataType) ? param?.dataType : '',
        _description: param?.description || '',
        _isRequired: isRequiredFlag,
        _enums: param?.enum || [],
        _samplePrompts: []
      }
    } else if(objectType === 'new-arg'){
      return {
        _title: '',
        _dataType: '',
        _description: '',
        _isRequired: null,
        _enums: [],
        _samplePrompts: []
      }
    } else {
      return {
        _title: objectName || '',
        _dataType: '',
        _description: '',
        _isRequired: null,
        _enums: [],
        _samplePrompts: []
      }
    }
  }

  applyObjectDetails = (objectName, objectType, object) => {
    // todo: simplify applyObjectDetails and getObjectDetails
    if(objectType === 'function') {
      let {title, description, samplePrompts, outputPrompt, status} = object
      let {functionDetails} = this.state
      if(objectName === title) {
        functionDetails.description = description
        functionDetails.samplePrompts = samplePrompts
        functionDetails.outputPrompt = outputPrompt
        functionDetails.status = status
        this.setState({functionDetails})
      }
    } else if(supportedDataTypes.includes(objectType) || objectType === 'new-arg' || objectName) {
      let {functionDetails} = this.state
      let {parameters} = functionDetails

      if(objectType === 'new-arg') {
        parameters.push(object)
      } else {
        let indexOfParam = parameters.findIndex(param => param.title === objectName)
        if(indexOfParam > -1) {
          parameters[indexOfParam] = object
        }
      }
      functionDetails['parameters'] = parameters
      this.setState({functionDetails})
    }
  }

  getScript = () => {
    const { editorRef } = this.state
    try {
      let view = editorRef?.current?.view
      const content = view.state?.doc.toString()
      return content
    } catch(err) {
      return this.state.script || ''
    }
  }

  cleanUpEditor = () => {
    const { editorRef } = this.state
    try {
      let view = editorRef?.current?.view
      view.dispatch({
        changes: {
          from: 0, 
          to: view.state.doc.length, 
          // insert: "# Write your Python 3 (Version 3.11) function here.\n# Each function has an average execution time of 2 seconds before being interrupted.\n# DO NOT CALL THE ENTRY POINT FUNCTION IN YOUR SCRIPT.\n#\n# This environment includes the following packages: BeautifulSoup, urllib3, requests, pyyaml.\n# We would love to hear from you regarding which packages you'd like to have installed.\n# Join our Discord at https://discord.com/invite/zGdUve2Nhk and let us know.\n",
          insert: defaultComment
        }
      })
    } catch(err) {
      console.log(err)
    }
  }

  loadEditor = (value) => {
    const { editorRef } = this.state
    try {
      let view = editorRef?.current?.view
      view.dispatch({
        changes: {
          from: 0, 
          to: view.state.doc.length, 
          insert: value
        }
      })
    } catch(err) {
      console.log(err)
    }
  }

  _getJSONScript = () => {
    const { jsonEditorRef } = this.state
    try {
      let view = jsonEditorRef?.current?.view
      const content = view.state?.doc.toString()
      return content
    } catch(err) {
      console.error(err)
      return null
    }
  }

  parseTestParams = () => {
    let params = this._getJSONScript()
    if(params) {
      try {
        const parsedJson = JSON.parse(params)
        return parsedJson
      } catch (error) {
        return null
      }
    } else return {}
  }

  handleFunctionTest = (name='', onSuccess=() => {}, onFail=() => {}) => {
    if(name) {
      const parameters = this.parseTestParams()
      const script = this.getScript()
      if(script) {
        let env = this.state.env?.map(e => e?._id ?? null)
        axios.post('function/execute', {name, script, parameters, env}).then(response => {
          var result = response?.data
          if(result?.hasOwnProperty('log') || 
            result?.hasOwnProperty('output') || 
            result?.hasOwnProperty('duration') ||
            result?.hasOwnProperty('error')
          ) {
            onSuccess(result)
          } else {
            onFail('Error: No Data')
          }
        }).catch(err => {
          console.log(err)
          onFail(err)
        })
      } else {
        onFail('Error: Empty Script')
      }
      // axios.post()
    } else {
      onFail('Error: No Function Name Provided')
    }
  }

  _getChatDataFromResponse = (response) => {
    if(response?.data && 
      response?.data?.choices?.length > 0 &&
      response?.data?.choices[0]?.message 
    ) {
      return response?.data?.choices[0]?.message
    } else {
      return null
    }
  }

  organizeContext = (contextObject) => {
    let keys = Object.keys(contextObject || {})
    if(keys.length > 0) {
      let context = 'User Information:\n'
      keys.forEach(k => {
        let c = contextObject[k]

        let value = c?.value
        if(value === '[currTime]') {
          value = new Date().toLocaleTimeString('en-US', {
            timeZoneName: 'short'
          })
        } else if(value === '[currDate]') {
          value = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        }
        context += `${c.title}: ${value}\n`
        if(c?.key === 'location' && c?.latitude && c?.longitude) {
          context += `User Latitude: ${c?.latitude}\nUser Longitude: ${c?.longitude}\n`
        }
        if(c?.key === 'email' && c?.value) {
          context += `Medium of Contact: Email, Chat Panel\n`
        }
      })
      return context
    } else return ''
  }

  handleFunctionPrompt = (prompt, onSuccess=() => {}, onFail=() => {}) => {
    let context = this.organizeContext(this.state.contexts)
    let convs = [...this.state.testPrompts, {role: 'user', content: prompt}]
    let prompts = convs.slice(-numOfMsgToHandle)
    let {functionDetails} = this.state
    functionDetails['script'] = this.getScript()
    functionDetails['env'] = this.state.env?.map(e => e?._id ?? null)
    axios.post('/function/sample-test', { functionDetails, prompts, context }, {signal: this.state.axiosController.signal}).then(response => {
      if(this._isMounted) {
        let reply = this._getChatDataFromResponse(response)
        if(reply === null) {
          this.setState({erroredPrompt: true, axiosController: new AbortController()})
          onFail()
        } else {
          convs.push(reply)
          this.setState({testPrompts: convs, isPromptLoading: false, axiosController: new AbortController()})
          onSuccess()
        }
      }
    }).catch(err => {
      if(err?.message === 'canceled') {
        this.setState({isPromptLoading: false, axiosController: new AbortController()})
      } else {
        this.setState({isPromptLoading: false, erroredPrompt: true, axiosController: new AbortController()})
      }
      onFail()
    })
  }

  handlePrompt = (prompt, onSuccess=() => {}, onFail=() => {}) => {
    let context = this.organizeContext(this.state.contexts)
    let metadata = this.state.contexts || {}
    let chatID = null
    if(this.state.chatID && this.state.chatID !== 'unknown') {
      chatID = this.state.chatID
    }
    if(!this.state.isPromptLoading && prompt) {
      this.setState({panelMode: 'action', erroredPrompt: false, isPromptLoading: true})
      // prompt testing
      if(this.state?.metadata?.promptTesting?.enabled === true) {
        this.handleFunctionPrompt(prompt, onSuccess, onFail)
      } else {
        var convs = [...this.state.prompts, {role: 'user', content: prompt}]
        var prompts = convs.slice(-numOfMsgToHandle)
        let endpoint = '/nelima'
        if(this.state.storageEnabled === true) {
          endpoint = '/nelima-v2'
        }
        axios.post(endpoint, {prompts, context, metadata, chatID}, {signal: this.state.axiosController.signal}).then(response => {
          if(this._isMounted) {
            let reply = this._getChatDataFromResponse(response)
            let returnedChatID = response?.data?.chatID || null
            if(reply === null) {
              this.setState({isPromptLoading: false, erroredPrompt: true, axiosController: new AbortController()})
              onFail()
            } else {
              convs.push(reply)
              this.setState({prompts: convs, isPromptLoading: false, chatID: returnedChatID, axiosController: new AbortController()})
              // check if there's any scheduleID, if so then refresh schedule panel
              if('scheduleID' in (response?.data?.completionMetadata ?? {})) {
                const {syncUser} = this.context
                syncUser()
              }
              // check if there's any completion trigger in response data
              if('operation' in (response?.data?.completionTrigger ?? {})) {
                // sync storage
                this.syncStorage()
              }
              if(returnedChatID && returnedChatID !== 'unknown') {
                // do nothing
              } else {
                localStorage.setItem('prompts', JSON.stringify(convs))
              }
              onSuccess()
            }
          }
        }).catch(err => {
          if(err?.message === 'canceled') {
            this.setState({isPromptLoading: false, axiosController: new AbortController()})
          } else {
            this.setState({isPromptLoading: false, erroredPrompt: true, axiosController: new AbortController()})
          }
          onFail()
        })
      }
    }
  }

  handleFunctionCreate = ({onSuccess=() => {}, onFailure=() => {}}) => {
    const {title, description, parameters, output, samplePrompts} = this.state
    const script = this.getScript()
    axios.post('function/create', {title, description, parameters, output, samplePrompts, script}).then(response => {
      if(this._isMounted && response.data?._id) {
        onSuccess(response.data._id)
      } else {
        onFailure('There was an error while creating the function!')
      }
    }).catch(err => {
      onFailure('There was an error while creating the function!')
    })
  }

  parseScript = () => {
    const script = this.getScript()
    let functions = parseFunctions(script) || []
    // set attributes
    return functions
  }

  lockFunctionDetails = (functionName) => {
    const script = this.getScript()
    const functions = parseFunctions(script)
    const functionDetails = functions.find(elem => elem.title === functionName)
    functionDetails.status = this.state.functionDetails.status
    this.setState({functionDetails})
  }

  resetFunctionDetails = () => {
    this.setState({functionDetails: {
      title: '',
      description: '',
      parameters: [],
      samplePrompts: [],
      output: '',
      status: ''
    }})
  }

  updateMetadata = (action='write', field, value) => {
    let {metadata} = this.state
    if(action === 'write') {
      metadata[field] = value
      this.setState({metadata})
    } else if(action === 'delete') {
      if(field in metadata) {
        delete metadata[field]
        this.setState({metadata})
      }
    }
  }

  setOverlay = (component, full=false, orientation='right') => {
    // todo: update this function to support closure of one at a time overlay. calling overlay:null results in closure of all panels.
    if(component === null) {
      this.setState({overlayFull: null, overlay: null, overlayLeft: null})
    } else if(full === true) {
      this.setState({overlayFull: component})
    } else if(orientation === 'right') {
      this.setState({overlay: component})
    } else if(orientation === 'left') {
      this.setState({overlayLeft: component})
    }
  }

  deleteObjectDetails = (objectName) => {
    let {functionDetails} = this.state
    let {parameters} = functionDetails
    parameters = parameters.filter(param => param.title !== objectName)
    functionDetails['parameters'] = parameters
    this.setState({functionDetails})
  }

  isValidFull = () => {
    const warningFlags = {}
    var isValid = true
    if(!this.isValidFunction({
      title: this.state?.functionDetails?.title,
      description: this.state?.functionDetails?.description,
      samplePrompts: this.state?.functionDetails?.samplePrompts
    })) {
      warningFlags[this.state?.functionDetails?.title] = false
      isValid = isValid && false
    } else {
      warningFlags[this.state?.functionDetails?.title] = true
    }

    let {parameters} = this.state.functionDetails
    parameters.forEach(param => {
      if(!this.isValidVariable(param)) {
        warningFlags[param.title] = false
        isValid = isValid && false
      } else {
        warningFlags[param.title] = true
      }
    })

    if(this.state?.functionDetails?.output) {
      warningFlags['output'] = true
    } else {
      warningFlags['output'] = false
      isValid = isValid && false
    }

    return {isValid, warningFlags}
  }

  isValidFunction = (object=null) => {
    if(object?.title?.length > 0) {
      if(object?.description?.length > 0) {
        if(object?.samplePrompts?.length > 0) return true
        else return false
      } else return false
    } else return false
  }

  isValidVariable = (object=null) => {
    if(object?.title?.length > 0) {
      if(object?.description?.length > 0) {
        if(object?.dataType?.length > 0) {
          if(object?.isRequired !== null) return true
          else return false
        } else return false
      } else return false
    } else return false
  }

  // todo: hardcoded removal of lvl2-envmanager before changing to another window. have to make it more generalized!!
  openWindow = (windowID) => {
    let currWinLvl = String(windowID).split('-')[0] || ''
    let {windowStack} = this.state
    windowStack = windowStack.filter(winID => {
      if(winID === 'lvl2-envmanager') {
        return false
      }
      const lvl = String(winID).split('-')[0] || ''
      if(lvl) {
        if(lvl !== currWinLvl) return true
        else return false
      } else return false
    })
    windowStack.push(windowID)
    this.setState({windowStack})
  }

  // removes full lvl or specific window in a level
  closeWindow = (windowID) => {
    let lvl = String(windowID).split('-')
    let {windowStack} = this.state
    if(lvl.length > 1) {
      windowStack = windowStack.filter(id => id !== windowID)
    } else {
      windowStack = windowStack.filter(id => String(id).split('-')[0] !== windowID)
    }
    this.setState({windowStack})
  }

  loadFunctions = (onSuccess=()=>{}, onFailure=()=>{}) => {
    axios.get('/function/list').then(response => {
      if(this._isMounted && response.data?.functions) {
        this.setState({myFunctions: response.data?.functions})
        onSuccess()
      }
    }).catch(err => {
      onFailure(err)
    })
  }

  integrateFunction = (onSuccess=() => {}, onFail=() => {}) => {
    const {title, description, parameters, output, outputPrompt, samplePrompts, status, metadata} = this.state.functionDetails
    let env = this.state.env ?? []
    env = env.map(e => e._id ?? null)
    const script = this.getScript()
    axios.post('function/create', {title, description, parameters, output, outputPrompt, samplePrompts, script, status, env, metadata}).then(response => {
      if(response?.data?._id && this._isMounted) {
        onSuccess()
      } else {
        onFail()
      }
    }).catch(err => {
      console.log(err)
      onFail()
    })
  }

  cleanFunctionDetails = () => {
    this.setState({
      functionDetails: {
        title: '',
        description: '',
        parameters: [],
        samplePrompts: [],
        output: '',
        status: ''
      },
      env: [],
      testPrompts: [],
      functionTestParams: {}
    })
    this.updateMetadata('delete', 'editorActive', false)
    this.cleanUpEditor()
  }

  temporaryStateManagement = (mode, stateName='tempSandboxState') => {
    // eliminates editor refs
    if(mode === 'save') {
      if(localStorage.getItem(stateName) === null) {
        let tempState = {...this.state}
        delete tempState.editorRef
        delete tempState.jsonEditorRef
        tempState.overlay = null
        tempState.script = this.getScript()
        localStorage.setItem(stateName, JSON.stringify(tempState))
      }
    } else if(mode === 'load') {
      let tempState = localStorage.getItem(stateName)
      if(tempState) {
        try {
          tempState = JSON.parse(tempState)
          this.setState(prevState => ({
            ...prevState,
            ...tempState
          }))
          this.loadEditor(tempState['script'] || '')
        } catch(err) {
          console.log('Failed to load temporary state')
        }
      }
      localStorage.removeItem(stateName)
    }
  }

  loadExample = () => {
    let exampleState = {
      functionDetails: {
        title: 'get_time',
        description: 'Given area and location the function call tell the current date and time.',
        parameters: [
          {
            title: 'area',
            description: 'Specifies the Area of the location, according to WorldTimeAPI.',
            dataType: 'str',
            isRequired: true,
            enum: ['Africa', 'America', 'Antarctica', 'Asia', 'Australia', 'Europe', 'Pacific']
          },
          {
            title: 'location',
            description: 'Specifies the location where time is needed. The name should be according to WorldTimeAPI, most name of a country or state.',
            dataType: 'str',
            isRequired: true
          }
        ],
        samplePrompts: ['What is the time in New York', 'What is the time in California'],
        output: 'str'
      },
      prompts: [],
      testPrompts: [],
      isPromptLoading: false,
      erroredPrompt: false,
      showSidePanel: null,
      panelMode: 'integrate',
      layout: 'pro',
      functionTestParams: {},
      script: "# Function Integration Example\n# This function utilizes WorldTimeAPI to fetch time.\n# Refer to https://worldtimeapi.org/ for details on the API.\n\nimport requests\n\n# It is a good practice (not mandatory) to include type annotations.\ndef get_time(area: str, location: str) -> str:\n  url = f'http://worldtimeapi.org/api/timezone/{area}/{location}'\n  try:\n    response = requests.get(url)\n    if response.status_code == 200:\n      data = response.json()\n      return f'{data[\"datetime\"]} {data[\"abbreviation\"]}'\n    else:\n      return 'Error: Unable to retrieve time data. Try different region and area.'\n  except Exception as e:\n    return f'Error: Try again later.'\n\n# Notice that the function is not called here.\n\n### STEP 1: Define a function that takes an action.\n### STEP 2: Select the function in 'Action Invocation' Component under Integrate Action Panel (in this example get_time).\n### STEP 3: Under 'Function Properties' click 'UPDATE' and provide 'Function Description' and 'Sample Prompt'. This allow LLMs to get an idea about your written function and intended action.\n### STEP 4: Under Function Arguments, all the arguments defined on the function are listed. Describe all the arguments under their corresponding section.\n### STEP 5: Select function return type.\n### STEP 6: Test function with prompt and see if it is working as intended.\n### STEP 7: Now you click 'INTEGRATE' (not present in this example) to add the action into Nelima's capability.\n\n",
      windowStack: ['lvl1-funcexample'],
      objectSetup: null,
      overlay: null,
      myFunctions: [],
      metadata: {editorActive: false}
    }
    this.temporaryStateManagement('save', 'original-state')
    this.setState(prevState => ({
      ...prevState,
      ...exampleState
    }))
    this.loadEditor(exampleState['script'] || '')
    setTimeout(() => {
      localStorage.setItem('example-reset-flag', 'true')
    }, 2000)
  }

  _preprocessAndSetPrompts = (chat) => {
    if(!Array.isArray(chat?.conversations)) return
    let prompts = []
    chat.conversations.forEach(c => {
      let role = c?.role || 'user'
      let content = c?.content || ''
      let reference = c?.reference || null
      let task = c?.task || null
      prompts.push({role, content, reference, task})
    })
    this.setState({prompts})
    return
  }

  loadChats = async (chatID=null) => {
    if(!this.state.isPromptLoading) {
      this.setState({ isPromptLoading: true, chatPanelSecondaryPlaceholderMsg: 'Loading Chats...' })
      try {
        let response = await axios.post('/function/chats', { chatID })
        if(response?.data?.chat) {
          // note: chat exists in server so get rid of local
          try {
            localStorage.removeItem('prompts')
          } catch(err) {
            // does not exist
          }
          try {
            this._preprocessAndSetPrompts(response?.data?.chat)
            this.setState({ 
              chatID: response?.data?.chat?.chatID || null,
              isPromptLoading: false, 
              chatPanelSecondaryPlaceholderMsg: null 
            })
          } catch(err) {
            this.setState({
              chatPanelSecondaryPlaceholderMsg: 'Error retrieving chats. Please refresh the page.'
            })
          }
        } else {
          // note: chat does not exist in server so check if local exist
          this.setState({ isPromptLoading: false, chatPanelSecondaryPlaceholderMsg: null })
          let prompts = localStorage.getItem('prompts')
          if(prompts) {
            // note: local exists, so ask to save in the server
            try {
              prompts = JSON.parse(prompts)
              if(prompts?.length > 1) {
                this.setOverlay(<ChatHistoryTransitionPanel/>, true)
              }
            } catch(err) {
              prompts = []
              localStorage.removeItem('prompts')
            }
          }
        }
      } catch(err) {
        this.setState({ 
          chatPanelSecondaryPlaceholderMsg: 'Error retrieving chats. Please refresh the page.'
        })
      }
    }
  }

  setFileMap = (updater) => {
    this.setState((prevState) => ({
      fileMap: typeof updater === 'function' ? updater(prevState.fileMap) : updater,
    }))
  }
  
  render() {
    return(
      <SandBoxContext.Provider value={{
        sandboxState: this.state,
        sandboxSetState: this.setState.bind(this),
        editorRef: this.state.editorRef,
        jsonEditorRef: this.state.jsonEditorRef,
        getObjectDetails: this.getObjectDetails,
        isValidFull: this.isValidFull,
        isValidFunction: this.isValidFunction,
        isValidVariable: this.isValidVariable,
        applyObjectDetails: this.applyObjectDetails,
        deleteObjectDetails: this.deleteObjectDetails,
        parseScript: this.parseScript,
        openWindow: this.openWindow,
        closeWindow: this.closeWindow,
        setOverlay: this.setOverlay,
        lockFunctionDetails: this.lockFunctionDetails,
        resetFunctionDetails: this.resetFunctionDetails,
        updateMetadata: this.updateMetadata,
        parseTestParams: this.parseTestParams,
        handleFunctionTest: this.handleFunctionTest,
        handlePrompt: this.handlePrompt,
        loadFunctions: this.loadFunctions,
        getScript: this.getScript,
        integrateFunction: this.integrateFunction,
        cleanFunctionDetails: this.cleanFunctionDetails,
        cleanupPrompts: this.cleanupPrompts,
        temporaryStateManagement: this.temporaryStateManagement,
        loadExample: this.loadExample,
        loadChats: this.loadChats,
        isEnvVarAttached: this.isEnvVarAttached,
        envVarListUpdate: this.envVarListUpdate,
        setFileMap: this.setFileMap,
        syncStorage: this.syncStorage
      }}>
        {this.props.children}
      </SandBoxContext.Provider>
    )
  }

  componentWillUnmount() {
		this._isMounted = false;
		if(this._intervalID) {
			clearInterval(this._intervalID)
		}
	}
}

const withSandBoxHandler = (Component) => {
  // attaching ref since react class components do not support react hooks
  return props => 
    <SandBoxHandler editorRef={useRef(null)} jsonEditorRef={useRef(null)}>
      <LLMContextLoader/>
      <LLMServiceLoader/>
      {/* <EventManager/> */}
      <NotificationManager/>
      <Component {...props}/>
    </SandBoxHandler>
}

const FsMap = {
  rootFolderId: 'My Drive/',
  fileMap: {
    'My Drive/': {
      id: 'My Drive/',
      name: 'My Drive',
      isDir: true,
      childrenIds: [],
      childrenCount: 0
    }
  }
}

export { withSandBoxHandler }
export default SandBoxContext