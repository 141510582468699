import React, { useState } from 'react'

const TabularViewer = ({ data }) => {
  return (
    <div className="sv2-table-container">
      <div className="sv2-table-viewer">
        <table className="sv2-table">
          {
            data?.meta?.fields?.length > 0 ?
            <thead className="sv2-table-head">
              <tr>{ data?.meta?.fields.map((col, i) => <td key={`head-${i}`}>{col}</td>) }</tr>
            </thead>
            :
            null
          }
          <tbody className="sv2-table-body">
            {
              data?.data?.length > 0 ?
              data?.data.map((elem, i) => {
                let row = Object.values(elem)
                if(row.length > 0) {
                  return (<tr key={`row-${i}`}>{ row.map((col, j) => <td key={`col-${i}-${j}`}>{col}</td>) }</tr>)
                } else {
                  return null
                }
              })
              :
              null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TabularViewer