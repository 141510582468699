import {React} from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from './pages'
import { Global } from './contexts/GlobalContext'
import '../src/assets/fonts/font-awesome.css'
import { Cache } from './contexts/CacheContext'

function App() {
  return (
    <div className="body header-fixed">
    <>
      <Global>
        <Cache>
          <Routes>
            {
              routes.map((data,idx) => (
                <Route key={idx} path={data.path} element={data.component} exact/>
              ))
            }
          </Routes>
        </Cache>
      </Global>
    </>
    </div>
  )
}

export default App
